<template>
  <div class="container">
    <div
      @click="goback"
      style="font-size: 14px; margin-bottom: 10px; cursor: pointer; width: 50px"
    >
      <i class="el-icon-back"></i>
      返回
    </div>
    <el-form :model="form" :rules="rules" ref="form" style="width: 650px">
      <el-form-item label="套餐名称" prop="name" label-width="250px">
        <el-input
          v-model="form.name"
          autocomplete="off"
          :disabled="edit"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="套餐时长"
        class="packtime"
        prop="time"
        label-width="250px"
      >
        <el-input-number
          v-model="form.time"
          :min="1"
          :disabled="edit"
        ></el-input-number>
        <el-select v-model="form.time_unit" :disabled="edit">
          <el-option label="DAY" value="DAY"></el-option>
          <el-option label="WEEK" value="WEEK"></el-option>
          <el-option label="MONTH" value="MONTH"></el-option>
          <el-option label="YEAR" value="YEAR"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="套餐时间内文件云存储时长"
        prop="file_time"
        label-width="250px"
      >
        <el-input-number
          v-model="form.file_time"
          :min="1"
          :disabled="edit"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        label="套餐价格/套餐售价/币种"
        prop="prices"
        label-width="250px"
      >
        <div v-for="(v, i) in form.prices" :key="i" class="packcurrency">
          <el-input
            v-model.number="v.price"
            autocomplete="off"
            placeholder="套餐价格"
          ></el-input>
          <el-input
            v-model.number="v.sale_price"
            autocomplete="off"
            placeholder="套餐售价"
          ></el-input>
          <el-select v-model="v.currency" placeholder="币种">
            <el-option
              v-for="item in currencyList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <div v-if="i === form.prices.length - 1">
            <span
              style="
                font-size: 24px;
                color: rgb(64, 158, 255);
                cursor: pointer;
                margin-right: 2px;
              "
              @click="addCurrency"
            >
              <i class="el-icon-circle-plus"></i>
            </span>
            <span
              style="font-size: 24px; color: #f56c6c; cursor: pointer"
              @click="subtractCurrency(i)"
              v-if="form.prices.length > 1"
            >
              <i class="el-icon-remove"></i>
            </span>
          </div>

          <div
            v-else
            style="font-size: 24px; color: #f56c6c; cursor: pointer"
            @click="subtractCurrency(i)"
          >
            <i class="el-icon-remove"></i>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="服务等级" label-width="250px">
        <el-select v-model="form.level" :disabled="edit">
          <el-option label="Starter" :value="1"> </el-option>
          <el-option label="Standard" :value="2"> </el-option>
          <el-option label="Pro" :value="3"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="功能code组" label-width="250px">
        <el-select v-model="form.function" multiple :disabled="edit">
          <el-option
            v-for="item in funclist"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否针对事件进行存储" label-width="250px">
        <el-radio-group v-model="form.is_event" :disabled="edit">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="说明" label-width="250px">
        <el-input
          type="textarea"
          :rows="5"
          v-model="form.explain"
          autocomplete="off"
          :disabled="edit"
        ></el-input>
      </el-form-item>
      <el-form-item label="" label-width="250px">
        <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)"
          >新增</el-button
        >
        <el-button type="primary" v-if="edit" @click="Validate(editconfirm)"
          >修改</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script type="text/ecmascript-6">
import api from "../../../axios/pack";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    var checkPrices = (rule, value, callback) => {
      console.log("checkPrices: ", value);
      let isValid = 0;
      for (let i = 0; i < value.length; i++) {
        const e = value[i];
        let reg = /^([1-9]\d*|0)(\.\d{1,2})?$/;
        console.log("sale_price: ", e.sale_price);
        if (!e.price || !e.sale_price) {
          console.log("不能为空");
          isValid = 1;
        } else {
          if (!reg.test(e.price) || !reg.test(e.sale_price)) {
            isValid = 2;
          }
        }
      }
      console.log("isValid: ", isValid);
      if (isValid === 1) {
        callback(new Error("不能为空"));
      } else if (isValid === 2) {
        callback(new Error("请输入整数或最多两位小数的数值型"));
      } else {
        callback();
      }
    };
    return {
      shortcuts: [
        {
          text: "今天",
          value: new Date(),
        },
        {
          text: "昨天",
          value: () => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          },
        },
      ],
      edit: false,
      packCode: "",
      title: "",
      form: {
        name: "",
        time: 1,
        file_time: 1,
        time_unit: "",
        function: [],
        explain: "",
        is_event: "",
        level: 1,
        prices: [
          {
            sale_price: "",
            price: "",
            currency: "USD",
          },
        ],
      },
      rules: {
        name: [{ required: true, message: "请输入套餐名称", trigger: "blur" }],
        time: [{ required: true, message: "请输入套餐时长", trigger: "blur" }],
        file_time: [
          {
            required: true,
            message: "请输入套餐时间内文件云存储时长",
            trigger: "blur",
          },
        ],
        time_unit: [
          { required: true, message: "请输入时间单位", trigger: "blur" },
        ],
        prices: [{ required: true, validator: checkPrices, trigger: "blur" }],
      },
      prolist: [],
      funclist: [],
      date: "",
      currencyList: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    GetCurrency() {
      this.api
        .CurrencyList({
          page: 1,
          limit: 100,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.currencyList = res.data.data.data;
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    selectTime() {
      if (this.date) {
        this.form.expire = this.date.getTime() / 1000;
      } else {
        this.form.expire = "";
      }
    },
    focusEvent() {
      if (!this.date) {
        this.form.expire = "";
      }
    },
    GetFuncList() {
      api
        .PackFuncList({
          page: 1,
          limit: 100,
          type: 4,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.funclist = res.data.data.data;
            this.GetCurrency();
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    GetproList() {
      this.api
        .ProductList({
          page: 1,
          limit: 100,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.prolist = res.data.data.data;
            this.GetFuncList();
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    GetInfo() {
      api.PackageDetail({ code: this.packCode }).then((res) => {
        if (res.data.code == 200) {
          this.form = res.data.data.info;
          this.form.function = JSON.parse(res.data.data.info.func_code);
          this.form.prices = res.data.data.prices.map((v) => ({
            sale_price: v.SalePrice,
            price: v.Price,
            currency: v.Currency,
          }));
          console.log("prices: ", this.form.prices);
          return;
        }
        this.$message.error("获取详情失败" + res.data.msg);
      });
    },
    Validate: function (call) {
      this.$refs["form"].validate((res) => {
        if (res && call) {
          call();
        }
      });
    },
    addconfirm() {
      this.createPack();
    },
    createPack() {
      api
        .PackageCreate({
          name: this.form.name,
          file_time: +this.form.file_time,
          service_time: +this.form.time,
          service_time_unit: this.form.time_unit,
          is_event: +this.form.is_event,
          function: this.form.function,
          level: this.form.level,
          explain: this.form.explain,
          prices: this.form.prices,
        })
        .then((res) => {
          console.log("createPack: ", res);
          if (res.data.code == 200) {
            this.form = {};
            this.$confirm(this.title + "成功，是否返回列表页?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.goback();
            });
            return;
          }
          this.$message.error(this.title + "失败" + res.data.msg);
        });
    },
    update() {
      api
        .PackageUpdatePrice({
          set_meal_code: this.packCode,
          prices: this.form.prices,
        })
        .then((res) => {
          console.log("PackageUpdatePrice:", res);
          if (res.data.code == 200) {
            this.form = {};
            this.$confirm(this.title + "成功，是否返回列表页?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.goback();
            });
            return;
          }
          this.$message.error(this.title + "失败" + res.data.msg);
        });
    },
    editconfirm() {
      this.update();
    },
    goback() {
      this.$router.push("/iot/pack");
    },
    subtractCurrency(i) {
      this.form.prices.splice(i, 1);
    },
    addCurrency() {
      this.form.prices.push({
        sale_price: "",
        price: "",
        currency: "USD",
      });
    },
  },
  filters: {},
  mounted() {
    this.packCode = this.$route.params.code;
    if (this.packCode !== "0") {
      this.GetInfo();
      this.title = "修改";
      this.edit = true;
    } else {
      this.title = "新增";
      this.edit = false;
    }
  },
  created() {
    this.GetproList();
  },
};
</script>

<style>
.packtime .el-form-item__content {
  display: flex;
}
.packcurrency {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.packcurrency .el-select {
  width: 120px;
  margin-right: 8px;
}
.packcurrency .el-input {
  width: 100px;
  margin-right: 8px;
}
</style>
